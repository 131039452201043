import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TaxSlabs20222023 from "../components/tax-slabs-2022-2023"

function TaxSlabs2022() {
    return (
        <Layout>
            <TaxSlabs20222023></TaxSlabs20222023>
        </Layout>
    );
}
export const Head = () => <Seo title="Tax Slabs FY 2022-2023 | Tax Calculator Pakistan" description="The following slabs and income tax rates will be applicable for salaried persons for the years 2022-2023 according to the new budget." />

export default TaxSlabs2022;